<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <div class="tabs">
      <v-tabs
        v-model="tab"
        background-color="light primary"
        class="elevation-2"
        dark
      >
        <v-tab>{{ $t("COMPONENTS.DESTRUCTION_PROTOCOL") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Възложител:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="clients"
                        label="name"
                        v-model="destructionPackaging.clientId"
                        :value="destructionPackaging.clientId"
                        :reduce="clients => clients.id"
                      ></vu-select>
                    </div>
                    <div v-if="$v.destructionPackaging.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.destructionPackaging.clientId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <hr />
              <template
                v-if="
                  destructionPackaging.products.length > 0 &&
                    materials.length > 0
                "
              >
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="(product, i) in destructionPackaging.products"
                    v-bind:key="i"
                  >
                    <v-expansion-panel-header>
                      <div class="text-left">
                        <h4>
                          {{ getMaterial(product.materialId) }} | Количество:
                          {{ product.quantity }}
                        </h4>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> Материал:</span>
                            </label>
                            <div class="field-wrap">
                              <vu-select
                                :options="materials"
                                label="optionName"
                                v-model="product.materialId"
                                :value="product.materialId"
                                :reduce="materials => materials.id"
                                @input="
                                  getCertificatesByMaterialId($event, product)
                                "
                              ></vu-select>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> Вътрешно партиден номер:</span>
                            </label>
                            <div class="field-wrap">
                              <vu-select
                                :options="product.certificates"
                                label="name"
                                v-model="product.certificateId"
                                :value="product.certificateId"
                                :reduce="certificates => certificates.id"
                              ></vu-select>
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="6">
                          <label for=""><span> Количество:</span></label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="product.quantity"
                          />
                        </v-col>
                        <v-col cols="6">
                          <div class="form-group">
                            <label for="">
                              <span> Причини за унищожаването</span>
                            </label>
                            <div class="field-wrap">
                              <textarea
                                v-model="product.reason"
                                class="form-control"
                              ></textarea
                              ><br />
                            </div>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            @click="deleteProduct(i)"
                            class="btn btn-danger pull-right btn-elevate kt-login__btn-primary text-white"
                            >{{ $t("BUTTON.DELETE") }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
              <v-row>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Материал:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="materials"
                        label="optionName"
                        v-model="newProduct.materialId"
                        :value="newProduct.materialId"
                        :reduce="materials => materials.id"
                        @input="getCertificatesByMaterialId($event, newProduct)"
                      ></vu-select>
                    </div>
                    <div v-if="$v.newProduct.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.newProduct.materialId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Вътрешно партиден номер:</span>
                    </label>
                    <div class="field-wrap">
                      <vu-select
                        :options="newProduct.certificates"
                        label="name"
                        v-model="newProduct.certificateId"
                        :value="newProduct.certificateId"
                        :reduce="certificates => certificates.id"
                      ></vu-select>
                    </div>
                    <div v-if="$v.newProduct.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.newProduct.certificateId.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Количество:</span>
                    </label>
                    <div class="field-wrap">
                      <input
                        type="text"
                        class="form-control"
                        v-model="newProduct.quantity"
                      />
                    </div>
                    <div v-if="$v.newProduct.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.newProduct.quantity.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>

                <v-col cols="6">
                  <div class="form-group">
                    <label for="">
                      <span> Причини за унищожаването</span>
                    </label>
                    <div class="field-wrap">
                      <textarea
                        v-model="newProduct.reason"
                        class="form-control"
                      ></textarea
                      ><br />
                    </div>
                    <div v-if="$v.newProduct.$error">
                      <div
                        class="text-danger"
                        v-if="!$v.newProduct.reason.required"
                      >
                        {{ $t("VALIDATIONS.REQUIRED") }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    @click="saveProducts()"
                    class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                    >Добави запис
                  </v-btn>
                </v-col>
              </v-row>
              <hr />
              <v-btn
                @click="save()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import {
  GET_DESTRUCTION_PACKAGING_MATERIAL,
  SAVE_DESTRUCTION_PACKAGING_MATERIAL,
  UPDATE_DESTRUCTION_PACKAGING_MATERIAL
} from "@/store/destructionPackagingMaterials.module";

import { GET_DATA } from "@/store/formselect.module";

import { GET_ALL_MATERIALS } from "@/store/materials.module";

import { GET_ALL_CLIENTS } from "@/store/clients.module";

import { GET_CERTIFICATES_BY_MATERIAL_ID } from "@/store/certificates.module";

import { mapGetters, mapState } from "vuex";

import { required } from "vuelidate/lib/validators";

import _ from "lodash";

function initProductData() {
  return {
    certificateId: null,
    materialName: null,
    certificates: [],
    materialId: null,
    quantity: null,
    reason: null
  };
}

export default {
  name: "createUpdate",

  components: {},
  computed: {
    ...mapGetters(["isLoadingDestructionPackagingMaterials"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    isEdit: function() {
      if (typeof this.$route.params.id !== "undefined") {
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      error_messages: [],
      model: "destructionPackaging",
      tab: 0,
      vendfors: [],
      destructionPackaging: {
        clientId: null,
        products: []
      },
      newProduct: initProductData(),
      materials: [],
      clients: [],
      materialTypes: []
    };
  },
  validations: {
    destructionPackaging: {
      clientId: {
        required
      }
    },
    newProduct: {
      materialId: {
        required
      },
      certificateId: {
        required
      },
      quantity: {
        required
      },
      reason: {
        required
      }
    }
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        {
          title: "Протокол за унищожаване на вторични опаковъчни материали",
          route: "/destruction-packaging-materials"
        },
        { title: current_page }
      ]);
    }, 300);

    this.getMaterialTypes();
    this.getMaterials();
    this.getClients();

    if (typeof this.$route.params.id !== "undefined") {
      // this.getFiles();
      this.getData();
    }
  },
  methods: {
    getCertificatesByMaterialId: function(materialId, product) {
      let vm = this;

      this.error_messages = [];

      product.certificateId = null;
      product.certificates = [];

      vm.$store
        .dispatch(GET_CERTIFICATES_BY_MATERIAL_ID, materialId)
        .then(data => {
          vm.$nextTick(function() {
            product.certificates = _.map(data.data, certificate => ({
              ...certificate,
              name: `${certificate.batch} (${certificate.residualQuantity} бр.)`
            }));
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;

          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getMaterial: function(materialId) {
      const material = _.find(this.materials, function(material) {
        return material.id == materialId;
      });

      return material ? material.optionName : " --- ";
    },
    deleteProduct: function(i) {
      let vm = this;

      vm.$confirm({
        title: "Сигурен ли си, че искаш да изтриеш този запис?",
        content: "За да завършите това действие, трябва да запишете промените!",
        icon: "warning",
        okText: "Потвърди",
        cancelText: "Откажи",
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        onOk() {
          vm.destructionPackaging.products.splice(i, 1);
        },
        onCancel() {}
      });
    },
    getMaterialTypes: function() {
      let vm = this;
      let payload = {
        materialTypes: {
          fields: ["id", "name"],
          search: ""
        }
      };

      this.error_messages = [];

      vm.$store
        .dispatch(GET_DATA, payload)
        .then(data => {
          vm.$nextTick(function() {
            vm.materialTypes = data.data.materialTypes;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getMaterials: function() {
      let vm = this;

      this.error_messages = [];
      vm.$store
        .dispatch(GET_ALL_MATERIALS)
        .then(data => {
          vm.$nextTick(function() {
            vm.materials = data.data;

            for (let i in vm.materials) {
              vm.materials[i].optionName =
                _.find(vm.materialTypes, function(o) {
                  return o.id == vm.materials[i].typeId;
                }).name +
                " - " +
                vm.materials[i].name;
            }
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    getClients: function() {
      let vm = this;

      this.error_messages = [];
      vm.$store
        .dispatch(GET_ALL_CLIENTS)
        .then(data => {
          vm.$nextTick(function() {
            vm.clients = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });
        });
    },
    save: function() {
      let vm = this;

      vm.error_messages = [];

      vm.$v.destructionPackaging.$touch();

      if (this.$v.destructionPackaging.$error) {
        vm.$scrollTo("html", 420, {
          offset: -300
        });
        return;
      }

      var action =
        typeof this.$route.params.id !== "undefined"
          ? UPDATE_DESTRUCTION_PACKAGING_MATERIAL
          : SAVE_DESTRUCTION_PACKAGING_MATERIAL;

      vm.$store
        .dispatch(action, { payload: vm.destructionPackaging })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$nextTick(function() {
            vm.destructionPackaging = data.data;
            vm.$router.push({ name: "destructionPackagingMaterialList" });
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "destructionPackagingsList" });
          }
        });
    },
    getData: function() {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;
      this.$store
        .dispatch(GET_DESTRUCTION_PACKAGING_MATERIAL, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.destructionPackaging = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "destructionPackagingsList" });
          }
        });
    },
    saveProducts: function() {
      let vm = this;

      this.$v.newProduct.$touch();

      if (this.$v.newProduct.$error) {
        return;
      }

      vm.$set(
        vm.destructionPackaging.products,
        vm.destructionPackaging.products.length,
        vm.newProduct
      );

      vm.$v.$reset();

      vm.newProduct = initProductData();
    }
  }
};
</script>
